import React, { Component } from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Link } from "react-router-dom";
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";
import Slider from "@material-ui/lab/Slider";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMale,
  faFemale,
  faCheck,
  faCircle,
  faFlagCheckered
} from "@fortawesome/free-solid-svg-icons";

library.add(faMale);
library.add(faFemale);
library.add(faCheck);
library.add(faCircle);
library.add(faFlagCheckered);

let data = {};
const debug = false;

function getSpecificTypeValue(valueUser, valueUrl, type) {
  if (type === "url") {
    return valueUrl;
  }
  return valueUser;
}

function getFilterUrl(
  gender,
  weight,
  pace,
  terrain,
  terrainType,
  activity,
  comfort,
  pronation
) {
  return (
    "https://www.bezeckepotreby.sk/kategoria/obuv?0" +
    (gender !== "undefined"
      ? "&" +
        getSpecificValue("gender", "slug", null) +
        "=" +
        getSpecificValue("gender", "url", gender)
      : "") +
    (terrain !== "undefined"
      ? "&" +
        getSpecificValue("terrain", "slug", null) +
        "=" +
        getSpecificValue("terrain", "url", terrain)
      : "") +
    (terrainType !== "undefined"
      ? "&" +
        getSpecificValue("terrainType", "slug", null) +
        "=" +
        getSpecificValue("terrainType", "url", terrainType)
      : "") +
    (activity !== "undefined"
      ? "&" +
        getSpecificValue("activity", "slug", null) +
        "=" +
        getSpecificValue("activity", "url", activity)
      : "") +
    (comfort !== "undefined"
      ? "&" +
        getSpecificValue("comfort", "slug", null) +
        "=" +
        getSpecificValue("comfort", "url", comfort, activity)
      : "") +
    (pronation !== "undefined"
      ? "&" +
        getSpecificValue("pronation", "slug", null) +
        "=" +
        getSpecificValue("pronation", "url", pronation)
      : "") +
    "#category-content"
  );
}

function getSpecificValue(component, type, numericValue, additionalDivider) {
  switch (component) {
    case "gender":
      if (type === "slug") {
        return "pohlavie";
      }

      if (type === "slug-frame") {
        return "pohlavie";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue("muž", "muži", type);
        case 2:
          return getSpecificTypeValue("žena", "ženy", type);
        default:
          // do nothing          
      }
      break;
    case "weight":
      if (type === "slug-frame") {
        return "váha";
      }

      return getSpecificTypeValue(numericValue + " kg", numericValue, type);
    case "pace":
      if (type === "slug-frame") {
        return "tempo";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue("≤ 4 min/km", "", type);
        case 2:
          return getSpecificTypeValue("≤ 5 min/km", "", type);
        case 3:
          return getSpecificTypeValue("≤ 6 min/km", "", type);
        case 4:
          return getSpecificTypeValue("> 6 min/km", "", type);
        default:
          // do nothing          
      }
      break;
    case "terrain":
      if (type === "slug") {
        return "typ-povrchu";
      }

      if (type === "slug-frame") {
        return "povrch";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue("cesta", "asfalt-cesta", type);
        case 2:
          return getSpecificTypeValue("terén", "terén", type);
        default:
          // do nothing          
      }
      break;
    case "terrainType":
      if (type === "slug") {
        return "typ-terenu";
      }

      if (type === "slug-frame") {
        return "terén";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue(
            "poľné cesty",
            "poľné%2Flesné%20cesty",
            type
          );
        case 2:
          return getSpecificTypeValue("les a skaly", "skaly", type);
        case 3:
          return getSpecificTypeValue("blato a sneh", "blato%2Fsneh", type);
        default:
          // do nothing          
      }
      break;
    case "activity":
      if (type === "slug") {
        return "typ-bezeckej-obuvi";
      }

      if (type === "slug-frame") {
        return "aktivita";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue("tréning", "tréningová", type);
        case 2:
          return getSpecificTypeValue("pretek", "preteková", type);
        default:
          // do nothing          
      }
      break;
    case "comfort":
      if (type === "slug") {
        return "miera-tlmenia";
      }

      if (type === "slug-frame") {
        return "pri behu chcem";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue("komfort", "stredná--vysoká", type);
        case 2:
          return getSpecificTypeValue(
            "rýchlosť",
            parseInt(additionalDivider, 10) === 1
              ? "stredná"
              : "stredná--n%C3%ADzka",
            type
          );
        default:
          // do nothing          
      }
      break;
    case "pronation":
      if (type === "slug") {
        return "doslap";
      }

      if (type === "slug-frame") {
        return "typ nášlapu";
      }

      switch (parseInt(numericValue)) {
        case 1:
          return getSpecificTypeValue("pronácia", "pronácia", type);
        case 2:
          return getSpecificTypeValue("neutrálny nášlap", "neutrálny", type);
        case 3:
          return getSpecificTypeValue("supinácia", "neutrálny", type);
        default:
          // do nothing          
      }
      break;
    default:
      return null;
  }
}

const ImageBackground = props => {
  return (
    <div className={"main"} style={{ backgroundImage: `url(${props.source})` }}>
      {props.children}
    </div>
  );
};

const Panel = props => {
  return <div className={"panel " + props.class}>{props.children}</div>;
};

const Box = props => {
  return <div className={"box " + props.class}>{props.children}</div>;
};

const Row = props => {
  return (
    <div className={"row " + props.class} style={props.style}>
      {props.children}
    </div>
  );
};

const Subtitle = props => {
  return <div className={"subtitle"}>{props.children}</div>;
};

const Text = props => {
  return <p style={props.style}>{props.children}</p>;
};

const Button = props => {
  return (
    <button className={"button " + props.class} onClick={props.onClick}>
      {props.children}
    </button>
  );
};

const View = props => {
  return (
    <Typography
      variant={props.variant ? props.variant : "body1"}
      align={props.align ? props.align : "center"}
    >
      {props.children}
    </Typography>
  );
};

const Dump = props => {
  if (!debug) {
    return null;
  }
  return <pre>{JSON.stringify(data)}</pre>;
};

const ResultsTable = props => {
  const {
    gender,
    weight,
    pace,
    terrain,
    terrainType,
    activity,
    comfort,
    pronation
  } = props;
  return (
    <Table>
      <TableBody>
        {gender !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Pohlavie
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("gender", "user", gender)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {weight !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Váha
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("weight", "user", weight)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {pace !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Tempo behu
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("pace", "user", pace)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {terrain !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Povrch
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("terrain", "user", terrain)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {terrainType !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Typ terénu
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("terrainType", "user", terrainType)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {activity !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Typ behu
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("activity", "user", activity)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {comfort !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Pri behu chcem
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("comfort", "user", comfort)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
        {pronation !== "undefined" ? (
          <TableRow>
            <TableCell component="th" scope="row">
              Typ nášlapu
            </TableCell>
            <TableCell align="right">
              <b>{getSpecificValue("pronation", "user", pronation)}</b>
            </TableCell>
          </TableRow>
        ) : (
          ""
        )}
      </TableBody>
    </Table>
  );
};

const Pagination = props => {
  const { activeStep } = props;
  return (
    <div class="pagination">
      <Link
        className={"item " + (activeStep >= 1 ? "checked" : "")}
        to={"/vyber-pohlavia"}
      >
        <FontAwesomeIcon
          style={{ fontSize: "2em", margin: "auto" }}
          icon={activeStep > 1 ? "check" : "circle"}
        />
      </Link>
      <Link
        onClick={e => {
          if (activeStep < 2) {
            e.preventDefault();
          }
        }}
        className={"item " + (activeStep >= 2 ? "checked" : "")}
        to={"/vyber-hmotnosti"}
      >
        <FontAwesomeIcon
          style={{ fontSize: "2em", margin: "auto" }}
          icon={activeStep > 2 ? "check" : "circle"}
        />
      </Link>
      <Link
        onClick={e => {
          if (activeStep < 3) {
            e.preventDefault();
          }
        }}
        className={"item " + (activeStep >= 3 ? "checked" : "")}
        to={"/vyber-terenu"}
      >
        <FontAwesomeIcon
          style={{ fontSize: "2em", margin: "auto" }}
          icon={activeStep > 3 ? "check" : "circle"}
        />
      </Link>
      <Link
        onClick={e => {
          if (activeStep < 4) {
            e.preventDefault();
          }
        }}
        className={"item " + (activeStep >= 4 ? "checked" : "")}
        to={"/vyber-aktivity"}
      >
        <FontAwesomeIcon
          style={{ fontSize: "2em", margin: "auto" }}
          icon={activeStep > 4 ? "check" : "circle"}
        />
      </Link>
      <Link
        onClick={e => {
          if (activeStep < 5) {
            e.preventDefault();
          }
        }}
        className={"item " + (activeStep >= 5 ? "checked" : "")}
        to={"/vyber-naslapu"}
      >
        <FontAwesomeIcon
          style={{ fontSize: "2em", margin: "auto" }}
          icon={activeStep > 5 ? "check" : "circle"}
        />
      </Link>
      <Link
        onClick={e => {
          if (activeStep < 6) {
            e.preventDefault();
          }
        }}
        className={"item " + (activeStep >= 6 ? "checked" : "")}
        to={"/suhrn"}
      >
        <FontAwesomeIcon
          style={{ fontSize: "2em", margin: "auto" }}
          icon="flag-checkered"
        />
      </Link>
    </div>
  );
};

class HomeScreen extends Component {
  render() {
    return (
      <ImageBackground source={require("./assets/img/01.jpg")}>
        <Panel>
          <img
            alt="Logo BEZECKEPOTREBY.sk - všetko pre beh od profesionálov"
            style={{ width: "200px" }}
            src="https://www.bezeckepotreby.sk/bezeckepotreby/images/obr/logo.svg"
          />
          <h1>
            Aplikácia pre výber <br /> správnej bežeckej obuvi
          </h1>
          <br />
          <br />
          <Link className={"button"} to={"/vyber-pohlavia"}>
            Začať test
          </Link>
        </Panel>
      </ImageBackground>
    );
  }
}

class GenderSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/02.jpg")}>
        <Panel>
          <h2 style={{ color: "white" }}>Aké je Vaše pohlavie?</h2>
          <Subtitle>Pokračujte prosím výberom pohlavia.</Subtitle>
          <Row>
            <a
              href="/"
              className={
                "button-rounded" + (this.state.checked === 1 ? " active" : "")
              }
              onClick={e => {
                e.preventDefault();
                data.gender = 1;
                this.setState(previousState => ({ checked: 1 }));
              }}
            >
              <FontAwesomeIcon
                style={{
                  display: "block",
                  fontSize: "2em",
                  color: "white",
                  margin: "auto",
                  marginBottom: "10px"
                }}
                icon="male"
              />
              {getSpecificValue("gender", "user", 1)}
            </a>

            <a
              href="/"
              className={
                "button-rounded" + (this.state.checked === 2 ? " active" : "")
              }
              onClick={e => {
                e.preventDefault();
                data.gender = 2;
                this.setState(previousState => ({ checked: 2 }));
              }}
            >
              <FontAwesomeIcon
                style={{
                  display: "block",
                  fontSize: "2em",
                  color: "white",
                  margin: "auto",
                  marginBottom: "10px"
                }}
                icon="female"
              />
              {getSpecificValue("gender", "user", 2)}
            </a>
          </Row>

          <Row style={{ marginTop: "30px" }}>
            {this.state.checked ? (
              <Link className={"button"} to={"/vyber-hmotnosti"}>
                Ďaľší krok
              </Link>
            ) : (
              ""
            )}
          </Row>
          <Dump />
        </Panel>
        <Pagination activeStep={1} />
      </ImageBackground>
    );
  }
}

class WeightSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: data.gender === 1 ? 80 : 55
    };
    data.weight = this.state.value;
  }

  handleChange = (event, value) => {
    this.setState(() => {
      return {
        value: parseFloat(value)
      };
    });
    data.weight = value;
  };

  render() {
    const { value } = this.state;
    const maxValue = 120;
    const minValue = 45;
    return (
      <ImageBackground source={require("./assets/img/03.jpg")}>
        <Panel class={"inverse"}>
          <h2>Vaša hmotnosť</h2>
          <Subtitle>Len pokojne, nesúdime :)</Subtitle>
          <Box>
            <Text>
              Bez ohľadu na Vašu výšku hrá hmotnosť pri výbere bežeckej obuvi
              dôležitú úlohu. Veď si len predstavte, že počas jedného bežeckého
              kroku vyvíjate na obuv tlak v násobku svojej hmotnosti.
            </Text>
            <Text>
              A práve preto je dôležité vybrať bežeckú obuv s mierou tlmenia
              presne podľa Vašich potrieb.
            </Text>
            <Slider
              step={1}
              // style={{ flex: 1 }}
              masx={maxValue}
              min={minValue}
              onChange={this.handleChange}
              value={value}
            />
            <Text
              style={{
                fontSize: "2.5em",
                textAlign: "center",
                marginTop: "40px"
              }}
            >
              {this.state.value} kg
            </Text>
            <Dump />
          </Box>
          <View>
            <Link className={"button"} to={"/tempo-behu"}>
              Ďalší krok
            </Link>
          </View>
        </Panel>
        <Pagination activeStep={2} />
      </ImageBackground>
    );
  }
}

class PaceSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: data.pace ? data.pace : null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/08.jpg")}>
        <Panel class={"inverse"}>
          <h2>Tempo behu</h2>
          <Subtitle>Rýchlosť akou behávate</Subtitle>
          <Box>
            <Text>
              Vyberte tempo v ktorom najčastejšie behávate. Tempo je časový
              údaj, za ktorý zvládnete zabehnúť jeden kilometer. Napríklad, keď
              bežíte 10 kilometrov za jednu hodinu, Vaše tempo je 6 minút na
              kilometer (6 min/km).
            </Text>
            <View>
              <Button
                class={this.state.checked === 1 ? "checked" : ""}
                onClick={() => {
                  data.pace = 1;
                  this.setState(previousState => ({ checked: 1 }));
                }}
              >
                {getSpecificValue("pace", "user", 1)}
              </Button>
              <Button
                class={this.state.checked === 2 ? "checked" : ""}
                onClick={() => {
                  data.pace = 2;
                  this.setState(previousState => ({ checked: 2 }));
                }}
              >
                {getSpecificValue("pace", "user", 2)}
              </Button>
              <Button
                class={this.state.checked === 3 ? "checked" : ""}
                onClick={() => {
                  data.pace = 3;
                  this.setState(previousState => ({ checked: 3 }));
                }}
              >
                {getSpecificValue("pace", "user", 3)}
              </Button>
              <Button
                class={this.state.checked === 4 ? "checked" : ""}
                onClick={() => {
                  data.pace = 3;
                  this.setState(previousState => ({ checked: 4 }));
                }}
              >
                {getSpecificValue("pace", "user", 4)}
              </Button>
            </View>
            <Dump />
          </Box>
          <View>
            {this.state.checked ? (
              <Link className={"button"} to={"/vyber-terenu"}>
                Ďalší krok
              </Link>
            ) : (
              ""
            )}
          </View>
        </Panel>
        <Pagination activeStep={3} />
      </ImageBackground>
    );
  }
}

class TerrainSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: data.terrain ? data.terrain : null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/03.jpg")}>
        <Panel class={"inverse"}>
          <h2>Povrch</h2>
          <Subtitle>Teraz musí padnúť rozhodnutie</Subtitle>
          <Box>
            <Text>
              Uvedomte si, že univerzálna bežecká obuv neexistuje. Bežecká obuv
              do terénu má prispôsobenú podrážku, zvršok, je odolnejšia a hlavne
              zaisťuje lepšiu smerovú stabilitu.
            </Text>
            <Text>
              Preto zvážte použitie cestnej bežeckej obuvi v teréne, či už pre
              jej rýchle opotrebovanie, v tom horšom prípade pre Vaše zranenie.
            </Text>
            <View>
              <Button
                class={this.state.checked === 1 ? "checked" : ""}
                onClick={() => {
                  data.terrain = 1;
                  this.setState(previousState => ({ checked: 1 }));
                }}
              >
                {getSpecificValue("terrain", "user", 1)}
              </Button>
              <Button
                class={this.state.checked === 2 ? "checked" : ""}
                onClick={() => {
                  data.terrain = 2;
                  this.setState(previousState => ({ checked: 2 }));
                }}
              >
                {getSpecificValue("terrain", "user", 2)}
              </Button>
            </View>
            <Dump />
          </Box>
          <View>
            {this.state.checked === 1 ? (
              <Link className={"button"} to={"/vyber-aktivity"}>
                Ďaľší krok
              </Link>
            ) : (
              ""
            )}
            {this.state.checked === 2 ? (
              <Link className={"button"} to={"/typ-terenu"}>
                Ďaľší krok
              </Link>
            ) : (
              ""
            )}
          </View>
        </Panel>
        <Pagination activeStep={3} />
      </ImageBackground>
    );
  }
}

class TerrainTypeSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: data.terrainType ? data.terrainType : null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/06.jpg")}>
        <Panel class={"inverse"}>
          <h2>Typ terénu</h2>
          <Subtitle>&nbsp;</Subtitle>
          <Box>
            <Text>
              Pri výbere bežeckej obuvi do terénu je na mieste zohľadniť o aký
              terén ide. Výsledkom bude lepšia priľnavosť, dlhšia životnosť a
              hlavne Vaša spokojnosť.
            </Text>
            <View>
              <Button
                class={this.state.checked === 1 ? "checked" : ""}
                onClick={() => {
                  data.terrainType = 1;
                  this.setState(previousState => ({ checked: 1 }));
                }}
              >
                {getSpecificValue("terrainType", "user", 1)}
              </Button>
              <Button
                class={this.state.checked === 2 ? "checked" : ""}
                onClick={() => {
                  data.terrainType = 2;
                  this.setState(previousState => ({ checked: 2 }));
                }}
              >
                {getSpecificValue("terrainType", "user", 2)}
              </Button>
              <Button
                class={this.state.checked === 3 ? "checked" : ""}
                onClick={() => {
                  data.terrainType = 3;
                  this.setState(previousState => ({ checked: 3 }));
                }}
              >
                {getSpecificValue("terrainType", "user", 3)}
              </Button>
            </View>
            <Dump />
          </Box>
          <View>
            {/* {this.state.checked ?
                <Link className={"button"} to={"/vyber-aktivity"}>Ďaľší krok</Link>
                : 
                ''
              } */}
            {this.state.checked ? (
              <Link className={"button"} to={"/pri-behu-chcem"}>
                Ďaľší krok
              </Link>
            ) : (
              ""
            )}
          </View>
        </Panel>
        <Pagination activeStep={3} />
      </ImageBackground>
    );
  }
}

class ActivityTypeSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: data.activity ? data.activity : null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/04.jpg")}>
        <Panel class={"inverse"}>
          <h2>Tréning alebo pretek</h2>
          <Subtitle>Výber je len na Vás.</Subtitle>
          <Box>
            <Text>
              Preteková bežecká obuv ponúka nižšiu mieru tlmenia, užší strih a
              nižšiu hmotnosť. Ak hľadáte práve tieto benefity, zvoľte pretek.
            </Text>
            <Text>
              Ak ste však rekreačný bežec, z hľadiska miery tlmenia a komfortu
              radšej siahnite po bežeckej obuvi na tréning.
            </Text>
            <View>
              <Button
                class={this.state.checked === 1 ? "checked" : ""}
                onClick={() => {
                  data.activity = 1;
                  this.setState(previousState => ({ checked: 1 }));
                }}
              >
                {getSpecificValue("activity", "user", 1)}
              </Button>
              <Button
                class={this.state.checked === 2 ? "checked" : ""}
                onClick={() => {
                  data.activity = 2;
                  this.setState(previousState => ({ checked: 2 }));
                }}
              >
                {getSpecificValue("activity", "user", 2)}
              </Button>
            </View>
            <Dump />
          </Box>
          <View>
            {this.state.checked ? (
              <Link className={"button"} to={"/pri-behu-chcem"}>
                Ďaľší krok
              </Link>
            ) : (
              ""
            )}
          </View>
        </Panel>
        <Pagination activeStep={4} />
      </ImageBackground>
    );
  }
}

class ComfortSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: data.comfort ? data.comfort : null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/07.jpg")}>
        <Panel class={"inverse"}>
          <h2>Pri behu chcem</h2>
          <Subtitle>Na čo má byť Vaša obuv zameraná?</Subtitle>
          <Box>
            <Text>
              Rýchlosť a tlmenie (teda komfort) sú dva bežecké svety, z ktorých
              si pri bežeckej obuvi musíme vybrať. Pokiaľ chcete čo najlepšie
              tlmenie, vyberte si KOMFORT. Ak pri behu chcete cítiť čo sa pod
              chodidlom deje a pocit rýchlosti je pre Vás cennejší ako tlmenie,
              zvoľte si RÝCHLOSŤ.
            </Text>
            <View>
              <Button
                class={this.state.checked === 1 ? "checked" : ""}
                onClick={() => {
                  data.comfort = 1;
                  this.setState(previousState => ({ checked: 1 }));
                }}
              >
                {getSpecificValue("comfort", "user", 1)}
              </Button>
              <Button
                class={this.state.checked === 2 ? "checked" : ""}
                onClick={() => {
                  data.comfort = 2;
                  this.setState(previousState => ({ checked: 2 }));
                }}
              >
                {getSpecificValue("comfort", "user", 2)}
              </Button>
            </View>
            <Dump />
          </Box>
          <View>
            {this.state.checked &&
            (data.activity === 2 || data.terrain === 2) ? (
              <Link
                className={"button"}
                to={
                  "/suhrn/" +
                  encodeURIComponent(data.gender) +
                  "/" +
                  encodeURIComponent(data.weight) +
                  "/" +
                  encodeURIComponent(data.pace) +
                  "/" +
                  encodeURIComponent(data.terrain) +
                  "/" +
                  encodeURIComponent(data.terrainType) +
                  "/" +
                  encodeURIComponent(data.activity) +
                  "/" +
                  encodeURIComponent(data.comfort) +
                  "/" +
                  encodeURIComponent(data.pronation)
                }
              >
                Zobraziť súhrn
              </Link>
            ) : (
              ""
            )}
            {this.state.checked && data.activity !== 2 && data.terrain === 1 ? (
              <Link className={"button"} to={"/vyber-naslapu"}>
                Ďaľší krok
              </Link>
            ) : (
              ""
            )}
          </View>
        </Panel>
        <Pagination activeStep={3} />
      </ImageBackground>
    );
  }
}

class PronationTypeSelectScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: data.pronation ? data.pronation : null
    };
  }
  render() {
    return (
      <ImageBackground source={require("./assets/img/04.jpg")}>
        <Panel class={"inverse"}>
          <h2>Nášlap</h2>
          <Subtitle>Ako je to skutočne s Vami?</Subtitle>
          <Box>
            <Text>
              Je nám jasné, že pre mnohých z Vás sú pojmy ako pronácia či
              supinácia úplne neznáme.
            </Text>
            <Text>
              A práve preto sme pre Vás pripravili jednoduchý test, ako to
              zistiť.{" "}
              <a className="highlight" target="_blank" rel="noopener noreferrer" href="https://www.bezeckepotreby.sk/stranka/test-doslapu">
                Otestujte sa tu
              </a>
              .
            </Text>
            <View>
              <a
                href="/"
                className="pronation"
                onClick={e => {
                  e.preventDefault();
                  data.pronation = 1;
                  this.setState(previousState => ({ checked: 1 }));
                }}
              >
                <img src={require("./assets/img/pronacia.png")} alt={"Obrázok pronácie"} />
                <input
                  type="checkbox"
                  name="pronation1"
                  value={1}
                  disabled
                  checked={this.state.checked === 1 ? true : false}
                />
                {/* Pronácia */}
              </a>
              <a
                href="/"
                className="pronation"
                onClick={e => {
                  e.preventDefault();
                  data.pronation = 2;
                  this.setState(previousState => ({ checked: 2 }));
                }}
              >
                <img src={require("./assets/img/neutralny-naslap.png")} alt={"Obrázok pre neutrálny nášlap"} />
                <input
                  type="checkbox"
                  name="pronation2"
                  value={2}
                  disabled
                  checked={this.state.checked === 2 ? true : false}
                />
                {/* Neutrálny nášlap */}
              </a>
              <a
                href="/"
                className="pronation"
                onClick={e => {
                  e.preventDefault();
                  data.pronation = 3;
                  this.setState(previousState => ({ checked: 3 }));
                }}
              >
                <img src={require("./assets/img/supinacia.png")} alt={"Obrázok supinácie"} />
                <input
                  type="checkbox"
                  name="pronation3"
                  value={3}
                  disabled
                  checked={this.state.checked === 3 ? true : false}
                />
                {/* Supinácia */}
              </a>
            </View>
            <Dump />
          </Box>
          <View>
            {this.state.checked ? (
              <Link
                className={"button"}
                to={
                  "/suhrn/" +
                  encodeURIComponent(data.gender) +
                  "/" +
                  encodeURIComponent(data.weight) +
                  "/" +
                  encodeURIComponent(data.pace) +
                  "/" +
                  encodeURIComponent(data.terrain) +
                  "/" +
                  encodeURIComponent(data.terrainType) +
                  "/" +
                  encodeURIComponent(data.activity) +
                  "/" +
                  encodeURIComponent(data.comfort) +
                  "/" +
                  encodeURIComponent(data.pronation)
                }
              >
                zobraziť súhrn
              </Link>
            ) : (
              ""
            )}
          </View>
        </Panel>
        <Pagination activeStep={5} />
      </ImageBackground>
    );
  }
}

class EmailScreen extends Component {
  render() {
    const {
      gender = data.gender,
      weight = data.weight,
      pace = data.pace,
      terrain = data.terrain,
      terrainType = data.terrainType,
      activity = data.activity,
      comfort = data.comfort,
      pronation = data.pronation
    } = this.props.match.params;
    return (
      <ImageBackground source={require("./assets/img/04.jpg")}>
        <Panel class={"inverse"}>
          <h2>Odoslať výsledky</h2>
          <Subtitle>Vaše výsledky budú odoslané na email</Subtitle>
          <Box>
            <ResultsTable
              gender={gender}
              weight={weight}
              terrain={terrain}
              terrainType={terrainType}
              activity={activity}
              pronation={pronation}
              pace={pace}
              comfort={comfort}
            />
            <iframe
              title={"Formulár na odoslanie emailu"}
              width="100%"
              src={
                "https://former.grandus.sk/bezeckepotreby/bezeckepotreby/app-form?" +
                (gender !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("gender", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("gender", "url", gender)
                    )
                  : "") +
                (weight !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("weight", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("weight", "url", weight)
                    ) +
                    "kg"
                  : "") +
                (pace !== "undefined"
                  ? "&" +
                    encodeURIComponent(getSpecificValue("pace", "slug-frame")) +
                    "=" +
                    encodeURIComponent(getSpecificValue("pace", "url", weight))
                  : "") +
                (terrain !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("terrain", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("terrain", "url", terrain)
                    )
                  : "") +
                (terrainType !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("terrainType", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("terrainType", "url", terrainType)
                    )
                  : "") +
                (activity !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("activity", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("activity", "url", activity)
                    )
                  : "") +
                (comfort !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("comfort", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("comfort", "url", comfort, activity)
                    )
                  : "") +
                (pronation !== "undefined"
                  ? "&" +
                    encodeURIComponent(
                      getSpecificValue("pronation", "slug-frame")
                    ) +
                    "=" +
                    encodeURIComponent(
                      getSpecificValue("pronation", "url", pronation)
                    )
                  : "") +
                "&odkaz=" +
                encodeURIComponent(
                  getFilterUrl(
                    gender,
                    weight,
                    pace,
                    terrain,
                    terrainType,
                    activity,
                    comfort,
                    pronation
                  )
                )
              }
            ></iframe>

            <Dump />
          </Box>
        </Panel>
        <Pagination activeStep={6} />
      </ImageBackground>
    );
  }
}

class SummaryScreen extends Component {
  render() {
    const {
      gender = data.gender,
      weight = data.weight,
      pace = data.pace,
      terrain = data.terrain,
      terrainType = data.terrainType,
      activity = data.activity,
      comfort = data.comfort,
      pronation = data.pronation
    } = this.props.match.params;
    return (
      <ImageBackground source={require("./assets/img/05.jpg")}>
        <Panel class={"inverse"}>
          <h2>Súhrn</h2>
          <Subtitle>Výsledky vašej analýzy</Subtitle>
          <Box>
            <Text>
              Výsledky našej analýzy sú zrkadlom Vami zvolených atribútov.
              Odporúčaná bežecká obuv plne zohľadňuje Vaše požiadavky a nároky
              pre správnu kombináciu tlmenia a stability. Rovnako reflektuje typ
              nášpapu tak, aby bola počas behu dosiahnutá maximálna efektivita
              behu. V prípade, že máte ďalšie otázky, neváhajte nás kontaktovať
              telefonicky (<a href="tel:+421948535530">0948 535 530</a> /{" "}
              <a href="tel:+421948535540">0948 535 540</a>) alebo emailom na{" "}
              <a href="mailto:info@bezeckepotreby.sk">info@bezeckepotreby.sk</a>
            </Text>
            <ResultsTable
              gender={gender}
              weight={weight}
              terrain={terrain}
              terrainType={terrainType}
              activity={activity}
              pronation={pronation}
              pace={pace}
              comfort={comfort}
            />

            <Dump />
          </Box>
          <Link
            className={"button white mobile-display-block"}
            to={
              "/odoslat-na-email/" +
              encodeURIComponent(gender) +
              "/" +
              encodeURIComponent(weight) +
              "/" +
              encodeURIComponent(pace) +
              "/" +
              encodeURIComponent(terrain) +
              "/" +
              encodeURIComponent(terrainType) +
              "/" +
              encodeURIComponent(activity) +
              "/" +
              encodeURIComponent(comfort) +
              "/" +
              encodeURIComponent(pronation)
            }
          >
            Poslať na email
          </Link>
          <a
            className={"button mobile-display-block"}
            target="_blank"
            href={getFilterUrl(
              gender,
              weight,
              pace,
              terrain,
              terrainType,
              activity,
              comfort,
              pronation
            )}
          >
            zobraziť bežeckú obuv
          </a>
        </Panel>
        <Pagination activeStep={6} />
      </ImageBackground>
    );
  }
}

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Route exact={true} path="/" component={HomeScreen} />
          <Route path="/vyber-pohlavia" component={GenderSelectScreen} />
          <Route path="/vyber-hmotnosti" component={WeightSelectScreen} />
          <Route path="/tempo-behu" component={PaceSelectScreen} />
          <Route path="/vyber-terenu" component={TerrainSelectScreen} />
          <Route path="/typ-terenu" component={TerrainTypeSelectScreen} />
          <Route path="/vyber-aktivity" component={ActivityTypeSelectScreen} />
          <Route path="/pri-behu-chcem" component={ComfortSelectScreen} />
          <Route path="/vyber-naslapu" component={PronationTypeSelectScreen} />
          <Route
            path="/odoslat-na-email/:gender/:weight/:pace/:terrain/:terrainType/:activity/:comfort/:pronation"
            component={EmailScreen}
          />
          <Route
            path="/suhrn/:gender/:weight/:pace/:terrain/:terrainType/:activity/:comfort/:pronation"
            component={SummaryScreen}
          />
        </div>
      </Router>
    );
  }
}

export default App;
